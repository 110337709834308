<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="报告名称" prop="iname">
        <Input v-model="form.iname" placeholder="报告名称"> </Input>
      </FormItem>
      <FormItem label="报告年份" prop="year">
        <ys-date-picker type="year" :disabled="noEditable" v-model="form.year" style="width: 100%;" placeholder="报告年份"></ys-date-picker>
      </FormItem>
      <FormItem label="报告类型" prop="type">
        <Select v-model="form.type" :disabled="noEditable" placeholder="报告类型">
          <Option v-for="(item,index) in baseData['机构报表']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="所属周期" prop="index" v-if="form.type!==365">
        <Select v-model="form.index" :disabled="noEditable" placeholder="所属周期">
          <Option v-for="(item,index) in cycleList" :value="item" :key="index">{{ item }} {{ unitName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="发送机构" prop="orgId">
        <Input v-model="form.orgName" readonly placeholder="发送机构">
          <Button slot="append" @click="choiceOrg('org')" :disabled="noEditable">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="接收机构" prop="receiveOrgId">
        <Input v-model="form.receiveOrgName" readonly placeholder="接收机构">
          <Button slot="append" @click="choiceOrg('receiveOrg')" :disabled="noEditable">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="服务总结" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows:2,maxRows: 5}" placeholder="服务总结"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/orgreport/AddOrEdit')">{{ form.id ? '修改报告' : '生成报告' }}</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="form.oType" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getMonthInfo} from "@/api/brief";
import OrgComponent from "_c/org";

export default {
  name: 'projectEdit',
  components: {OrgComponent,},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写报告名称', trigger: 'blur'}
        ],
        index: [
          {required: true, type: 'number', message: '请填写所属周期', trigger: 'change'}
        ],
        orgId: [
          {required: true, type: 'number', message: '请选择发送机构', trigger: 'change'}
        ],
        receiveOrgId: [
          {required: true, type: 'number', message: '请选择接受机构', trigger: 'change'}
        ],
      },
      orgFlag: false,
      orgType: '',
      form: {
        id: '',
        data: '',
        iname: '',
        orgName: '',
        receiveOrgName: '',
        orgId: '',
        receiveOrgId: '',
        type: 30,
        year: new Date(),
        index: null,
        desc: '',
      },
    }
  },
  watch: {
    type: function () {
      this.form.index = this.form.type === 30 ? new Date().getMonth() + 1 : 1;
    },
  },
  computed: {
    noEditable() {
      return !!this.form.id
    },
    unitName() {
      let value = '';
      switch (this.form.type) {
        case 365:
          value = '年';
          break;
        case 30:
          value = '月';
          break;
        case 90:
          value = '季度';
          break;
        case 182:
          value = '半年报';
          break;
      }
      return value;
    },
    cycleList: function () {
      let value = '';
      switch (this.form.type) {
        case 365:
          value = 1;
          break;
        case 30:
          value = 12;
          break;
        case 90:
          value = 4;
          break;
        case 182:
          value = 2;
          break;
        default:
          value = 12;
          break;
      }
      return value;
    },
  },
  async mounted() {
    if (this.mid) {
      await getMonthInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          if (item === 'year') {
            this.form.year = new Date(String(res.data.year))
          } else {
            this.form[item] = res.data[item] || null
          }
        });
      });
    }else{
      this.form.index = this.form.type === 30 ? new Date().getMonth() + 1 : 1;
    }
  },
  methods: {
    getData(data) {
      if (this.form[this.orgType + 'Id'] !== data[0].id) {
        this.form.checks = [];
      }
      this.form[this.orgType + 'Id'] = data[0].id;
      this.form[this.orgType + 'Name'] = data[0].iname;
    },
    choiceOrg(type) {
      this.orgFlag = true;
      this.orgType = type;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
